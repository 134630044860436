// React Imports
import React from 'react';
// Styles
import 'components/card/CommentBox/CommentBox.style.scss';
import { GENERAL } from '../../../assets/images/images';
import Loader from '../../loader/loader';
import Helper from '../../../core/helper/helper';
import Modal, { Information } from '../../modal/modal';
import Emitter from '../../../core/services';

const cLabels = {
  internalNoteSaveTitle: "Deseja guardar notas pessoais, lembranças suas, o que desejar?",
  internalMessageTitle: "Deseja enviar comentários/observações sobre o serviço?",
  liteInternalNotePlaceholder: "Inicie sessão para gravar notas pessoais.",
  liteSendMessagePlaceholder: "Inicie sessão para enviar comentários.",
  internalNotePlaceholder: "Notas pessoais",
  sendMessagePlaceholder: "Comentários/observações",
}

/**
 * Props definition
 * @typedef  {Object}  Props
 * @property {string}  observations 
 * @property {'internal'}  obsType 
 * @property {boolean} readOnly
 * @property {(observations: string) => void} saveObservation 
 */

class CommentBox extends React.Component {

  constructor(props) {
    super(props);
    this.refInput = React.createRef();
    this.refModal = React.createRef();

    this.state = {
      comment: props.observations || '',
      count: props.observations?.length,
      readOnly: props.readOnly,
      noClickAction: props.noClickAction,
      showSendButton: false,
      isLoading: false,
      hasLiteMessage: props.readOnly && props.obsType === 'internal',
      isBoxShining: false,
    }
  }

  componentDidMount() {
    Emitter.on('INTERNAL_NOTES_SHINING', () => {
      this.setState({ isBoxShining: true });
      setTimeout(() => {
        this.setState({ isBoxShining: false });
      }, 1000);
    });
  }

  componentDidUpdate(/** @type {Props} */ prevProps) {
    if (prevProps !== this.props) {
      /** @type {Props} */
      const { observations } = this.props;
      this.setState({ comment: observations, isLoading: false });
    }
  }

  handleObsChange = () => {
    this.setState({ count: this.refInput.current?.value?.length });
    if (this.refInput.current.value !== this.state.comment) {
      this.setState({ showSendButton: true });
    }
  }

  handleObsSave = () => {
    const { hasLiteMessage, comment } = this.state;
    if (hasLiteMessage) {
      this.renderModal(
        <Information
          title={cLabels.internalNoteSaveTitle}
          //text={Labels.liteGenericMessage}
          confirmText={Helper.getLiteRegisterButton()}
          onClick={() => (Helper.logoutFromLite())}
        />
      );
      return;
    }
    if (this.refInput.current.value !== comment && !hasLiteMessage) {
      this.setState({ comment: this.refInput.current.value, showSendButton: false, isLoading: true });
      this.props.saveObservation(this.refInput.current.value);
    }
  }

  renderTextFooter = (count) => {
    if (count >= 600) {
      return (
        <div className="w-100 d-flex justify-content-end normal-regular-black">{count}/1000</div>
      );
    }
  }

  renderSendButton = () => {
    const { showSendButton, isLoading } = this.state;
    const { obsType } = this.props;
    
    if (isLoading) {
      return (
        <div className="inside-textarea-button-container loading" >
          <Loader message='' inverted={true} local={true} big={false} small={true} />
        </div>
      );
    }

    if (!showSendButton) {
      return;
    }

    return (
      <div className="inside-textarea-button-container background-color-blue-dark" 
      style={{ boxShadow: '1px 0 8px 0 #000', 
              padding: '16px'}}
      role={'button'} 
      onClick={this.handleObsSave}>
        <img src={obsType === 'internal' ? GENERAL.save : GENERAL.send}
        className="filter-white" 
        style={{ height: '22px' }}
        alt="send-icon" />
      </div>
    );
  }

  renderModalLiteMode = (type) => {
    let label = undefined;
    if (type === "internal") {
      label = cLabels.internalNoteSaveTitle;
    } else if (type === "message") {
      label = cLabels.internalMessageTitle;
    } else {
      return;
    }

    this.renderModal(
      <Information
        title={label}
        //text={Labels.liteGenericMessage}
        confirmText={Helper.getLiteRegisterButton()}
        onClick={() => (Helper.logoutFromLite())}
      />
    );
  }

  renderModal = (view) => {
    this.refModal.current.renderView(view);
    this.refModal.current.openModal();
  }

  getPlaceholderText(obsType) {
    if (Helper.isLiteVersion()) {
      if (obsType === 'internal') {
        return cLabels.liteInternalNotePlaceholder;
      } else if (obsType === 'message') {
        return cLabels.liteSendMessagePlaceholder;
      } else {
        return "Observações";
      }
    } else {
      if (obsType === 'internal') {
        return cLabels.internalNotePlaceholder;
      } else if (obsType === 'message') {
        return cLabels.sendMessagePlaceholder;
      } else {
        return "Observações";
      }
    }
  }

  onClickLaunchModal(obsType, readOnly) {
    if (this.state.noClickAction) return;
    if (readOnly) {
      if (obsType === 'internal') {
        this.renderModalLiteMode("internal");
      }
      if (obsType === 'message') {
        this.renderModalLiteMode("message");
      }
    } else {
      return;
    }
  }

  render() {
    const { comment, readOnly, count, isBoxShining } = this.state;
    const { obsType } = this.props;
    let renderBorderClass = comment !== ''
      ? obsType === 'internal'
        ? isBoxShining
          ? 'border-2-blue-dark'
          : 'border-1-blue'
        : 'border-1-yellow'
      : '';
    return (
      <div className={`text-area-container w-100 h-100 flex-column`}>
        <div className="w-100 d-flex justify-content-start normal-light-black mb-2">
          {Helper.observationCommentLabel(obsType)}
        </div>
        <textarea
          ref={this.refInput}
          defaultValue={comment}
          maxLength="1000"
          onChange={this.handleObsChange}
          rows={3}
          className={`text-area-feedback h-100 w-100 ${renderBorderClass}`}
          placeholder={this.getPlaceholderText(obsType)}
          readOnly={readOnly}// && !hasLiteMessage
          onClick={() => this.onClickLaunchModal(obsType,readOnly)}
        />
        {this.renderSendButton()}
        {this.renderTextFooter(count)}
        <Modal ref={this.refModal} />
      </div>
    );
  }
}

export default CommentBox
